import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Dashboard from "pages/Dashboard";
type Menus = {
  [menu: string]: {
    label: string;
    icon: IconDefinition;
    path: string;
    subpath?: {
      label: string;
      path: string;
    }[];
  }[];
};

export const MENUS: Menus = {
  dashboard: [
    {
      label: "Dashboard",
      icon: icon({ name: "house" }),
      path: "/",
    },
  ],
  master: [
    {
      label: "Modul",
      icon: icon({ name: "book" }),
      path: "/modul",
    },
    {
      label: "Soal",
      icon: icon({ name: "circle-question" }),
      path: "/bank-soal",
    },
    {
      label: "Silabus",
      icon: icon({ name: "clipboard-list" }),
      path: "/silabus",
    },
  ],
  main: [
    
    {
      label: "Program",
      icon: icon({ name: "book-open-reader" }),
      path: "/program",
    },
    {
      label: "Tanya PR",
      icon: icon({ name: "comment-dots" }),
      path: "tanya-pr",
      subpath: [
        {
          label: "List tanya PR",
          path: "/tanya-pr/list",
        },
        {
          label: "Pengajar",
          path: "/tanya-pr/pengajar",
        },
        {
          label: "Penjualan",
          path: "/tanya-pr/penjualan",
        },
        {
          label: "Pengaturan",
          path: "/tanya-pr/pengaturan",
        },
      ],
    },
    {
      label: "Poin",
      icon: icon({ name: "coins" }),
      path: "/poin",
      subpath: [
        {
          label: "Penggunaan poin",
          path: "/poin/penggunaan-poin",
        },
        {
          label: "Pengaturan",
          path: "/poin/pengaturan",
        },
      ],
    },
    {
      label: "Live class",
      icon: icon({ name: "users" }),
      path: "live-class",
      subpath: [
        {
          label: "Kelender",
          path: "/live-class/calendar",
        },
        {
          label: "Monitoring",
          path: "/live-class/monitoring",
        },
      ],
    },
  ],
  config: [
    {
      label: "Upload masal",
      icon: icon({ name: "cloud-arrow-up" }),
      path: "/mass-upload",
    },
  ],
  users: [
    {
      label: "Staff Admin",
      icon: icon({ name: "user", style: "regular" }),
      path: "/admin",
    },
    {
      label: "Siswa",
      icon: icon({ name: "circle-user", style: "regular" }),
      path: "/siswa",
    },
    {
      label: "Sekolah",
      icon: icon({ name: "building", style: "regular" }),
      path: "/school-staff",
    },
  ],
  teacher: [
    {
      label: "Guru",
      icon: icon({ name: "chalkboard-user" }),
      path: "/guru",
    },
    {
      label: "Cuti",
      icon: icon({ name: "calendar" }),
      path: "teacher-leave",
    },
    {
      label: "Penarikan gaji",
      icon: icon({ name: "wallet" }),
      path: "/withdrawal",
    },
  ],
  promotion: [
    {
      label: "Riwayat Pesanan",
      icon: icon({ name: "dollar" }),
      path: "orders",
    },
    {
      label: "Promo & Voucher",
      icon: icon({ name: "tags" }),
      path: "promo",
    },
    {
      label: "Bundling",
      icon: icon({ name: "boxes-packing" }),
      path: "bundling",
    },
  ],
};

export const PAGE_LIMIT = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
];

type Difficulty = {
  [key: number]: string;
};

export const QUESTION_DIFFICULTY: Difficulty = {
  1: "MUDAH",
  2: "SEDANG",
  3: "SULIT",
};

type STATUS = {
  [status: string]: {
    label: string;
    color: BadgeColor;
  };
};

export const FILE_STATUS: STATUS = {
  failed: {
    label: "GAGAL",
    color: "error",
  },
  in_process: {
    label: "DIPROSES",
    color: "primary",
  },
  unprocessed: {
    label: "BELUM DIPROSES",
    color: "citrine",
  },
  success: {
    label: "SUKSES",
    color: "success",
  },
};

export const FILE_TYPE = [
  {
    label: "User",
    value: "siswa",
    description: "Tambahkan user siswa secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_STUDENT || "",
  },
  {
    label: "Layanan program",
    value: "langganan",
    description: "Tambahkan langganan program bagi siswa secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_SUBSCRIPTION || "",
  },
  {
    label: "Soal",
    value: "soal",
    description: "Tambahkan soal secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_QUESTION || "",
  },
  {
    label: "User sekolah",
    value: "school_users",
    description: "Tambahkan daftar guru secara massal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_SCHOOL_USERS || "",
  },
];

export const PROGRAM_TYPE = {
  learn: "E-learning",
  class: "Live class",
  tryout: "Try out",
  ask_homework: "Tanya PR",
};

export const PROGRAM_CATEGORY = [
  {
    label: "E-learning",
    value: "learn",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
  {
    label: "Live Class",
    value: "class",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
  {
    label: "Try Out",
    value: "tryout",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
];

export const PARENTAL_STATUS = [
  {
    id: "father",
    name: "Ayah",
  },
  {
    id: "mother",
    name: "Ibu",
  },
  {
    id: "guardian",
    name: "Wali",
  },
];

export const PROMO_CATEGORY = [
  {
    label: "Promo",
    value: "promo_regular",
    description:
      "Promo akan otomatis memotong harga pada saat pengguna melakukan checkout.",
  },
  {
    label: "Voucher",
    value: "voucher_code",
    description:
      "Voucher akan memotong harga program jika pengguna memasukkan kode voucher pada saat checkout.",
  },
];

export const DEFAULT_PAGINATION: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 0,
};
export const commonLatexSymbols = [
  "\\frac",
  "\\sqrt",
  "\\div",
  "\\times",
  "\\sum",
  "\\int",
  "\\pi",
  "\\theta",
  "\\alpha",
  "\\beta",
  "\\gamma",
  "\\delta",
  "\\epsilon",
  "\\zeta",
  "\\eta",
  "\\iota",
  "\\kappa",
  "\\lambda",
  "\\mu",
  "\\nu",
  "\\xi",
  "\\omicron",
  "\\rho",
  "\\sigma",
  "\\tau",
  "\\upsilon",
  "\\phi",
  "\\chi",
  "\\psi",
  "\\omega",
  "\\sin",
  "\\cos",
  "\\tan",
  "\\cot",
  "\\sec",
  "\\csc",
  "\\arcsin",
  "\\arccos",
  "\\arctan",
  "\\sinh",
  "\\cosh",
  "\\tanh",
  "\\log",
  "\\ln",
  "\\exp",
  "\\lim",
  "\\infty",
  "\\sum",
  "\\prod",
  "\\int",
  "\\oint",
  "\\bigcup",
  "\\bigcap",
  "\\bigsqcup",
  "\\bigvee",
  "\\bigwedge",
  "\\bigodot",
  "\\bigotimes",
  "\\bigoplus",
  "\\langle",
  "\\rangle",
  "\\lfloor",
  "\\rfloor",
  "\\lceil",
  "\\rceil",
  "\\in",
  "\\notin",
  "\\subset",
  "\\supset",
  "\\subseteq",
  "\\supseteq",
  "\\nsubseteq",
  "\\nsupseteq",
  "\\cup",
  "\\cap",
  "\\setminus",
  "\\emptyset",
  "\\forall",
  "\\exists",
  "\\nexists",
  "\\neg",
  "\\land",
  "\\lor",
  "\\oplus",
  "\\otimes",
  "\\perp",
  "\\parallel",
  "\\asymp",
  "\\equiv",
  "\\approx",
  "\\ne",
  "\\le",
  "\\ge",
  "\\ll",
  "\\gg",
  "\\sim",
  "\\simeq",
  "\\cong",
  "\\bowtie",
  "\\propto",
  "\\pm",
  "\\mp",
  "\\cdot",
  "\\times",
  "\\div",
  "\\star",
  "\\ast",
  "\\circ",
  "\\bullet",
  "\\diamond",
  "\\bigcirc",
  "\\doteq",
  "\\cong",
  "\\equiv",
  "\\approx",
  "\\asymp",
  "\\not\\approx",
  "\\propto",
  "\\parallel",
  "\\perp",
  "\\ni",
  "\\owns",
  "\\overline",
  "\\hat",
  "\\coprod",
  "\\wedge",
  "\\vee",
  "\\dagger",
  "\\ddagger",
  "\\odot",
  "\\varnothing"
];

export * from "./api";
